import React, {useEffect} from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "sections/Navigation"
import BlogSinglePage from "sections/Blog-single"
import Footer from "sections/Footer"

import theme from "assets/theme/theme"
import GlobalStyle from "assets/theme"
import {AgendaPage, SpeakersPage} from "../sections/Blog-single";
import ReactGA from "react-ga";
import {TRACKING_ID} from "../assets/data/config.js";
import GA4React from "ga-4-react";
// ReactGA.initialize(TRACKING_ID);
const AgendaGuide = () => {
    const ga4react = new GA4React(TRACKING_ID);
    useEffect(() =>{
        ga4react.initialize().then((ga4) => {
            ga4.pageview('Agenda')
            ga4.gtag('event','pageview','agenda')
        }).catch()
    }, [])

    return (<ThemeProvider theme={theme}>
        <Layout>
            <GlobalStyle />
            <SEO title="Agenda" />
            <Navigation id={'agenda'}/>
            <AgendaPage/>
            <Footer />
        </Layout>
    </ThemeProvider>)

}
export default AgendaGuide